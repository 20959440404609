import React from 'react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';

const SmallChart = ({ data, title, onClick }) => {
  const latestData = data[data.length - 1];
  const margin = Math.abs(latestData.D - latestData.R);
  const leadingParty = latestData.D > latestData.R ? 'D' : 'R';

  return (
    <div className="bg-white rounded-lg shadow-md p-2 flex flex-col cursor-pointer w-full" onClick={onClick}>
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm font-semibold">{title}</span>
        <span className={`text-xs font-bold ${leadingParty === 'D' ? 'text-blue-600' : 'text-red-600'}`}>
          {leadingParty}+{margin}
        </span>
      </div>
      <ResponsiveContainer width="100%" height={30}>
        <LineChart data={data}>
          <Line type="monotone" dataKey="D" stroke="#0000FF" strokeWidth={2} dot={false} />
          <Line type="monotone" dataKey="R" stroke="#FF0000" strokeWidth={2} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const smallChartsData = {
  President: {
    AZ: { title: "CB TR", data: [
      { date: '2024-06', R: 36, D: 30 },
      { date: '2024-07', R: 39, D: 35 },
      { date: '2024-08', R: 40, D: 38 },
      { date: '2024-09', R: 43, D: 48 },
      { date: '2024-10', R: 48, D: 51 }
    ]},
    FL: { title: "CB İst", data: [
      { date: '2024-06', R: 48, D: 43 },
      { date: '2024-07', R: 49, D: 44 },
      { date: '2024-08', R: 50, D: 45 },
      { date: '2024-09', R: 51, D: 46 },
      { date: '2024-10', R: 52, D: 47 }
    ]},
    // Add more states...
  },
  Senate: {
    NE: { title: "Meclis TR", data: [
      { date: '2024-06', R: 46, D: 45 },
      { date: '2024-07', R: 47, D: 46 },
      { date: '2024-08', R: 48, D: 47 },
      { date: '2024-09', R: 49, D: 48 },
      { date: '2024-10', R: 50, D: 49 }
    ]},
    OH: { title: "Meclis İst", data: [
      { date: '2024-06', D: 47, R: 45 },
      { date: '2024-07', D: 48, R: 46 },
      { date: '2024-08', D: 49, R: 47 },
      { date: '2024-09', D: 50, R: 48 },
      { date: '2024-10', D: 51, R: 49 }
    ]},
    // Add more states...
  }
};

export { SmallChart, smallChartsData };