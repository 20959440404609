import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

function App() {
  const [data, setData] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(null); // Store the current price

  useEffect(() => {
    // Connect to WebSocket
    const ws = new WebSocket("wss://stream.binance.com:9443/ws/btcusdt@kline_1m");

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const kline = message.k;

      // Extract relevant data from the WebSocket message
      const time = new Date(kline.t).toLocaleTimeString();
      const open = parseFloat(kline.o);
      const close = parseFloat(kline.c);
      const high = parseFloat(kline.h);
      const low = parseFloat(kline.l);

      // Update the data array
      setData((prevData) => {
        const newData = [...prevData, { time, open, close, high, low }];
        // Keep the last 100 data points (for performance)
        return newData.slice(-100);
      });

      // Set the current price to display
      setCurrentPrice(close);
    };

    return () => {
      ws.close();
    };
  }, []);

  // Prepare chart data
  const chartData = {
    labels: data.map(item => item.time),
    datasets: [
      {
        label: 'BTC/USDT',
        data: data.map(item => item.close),
        borderColor: 'rgb(75, 192, 192)',
        fill: false,
      },
    ],
  };

  useEffect(() => {
    const logMemoryUsage = () => {
      if (window.performance && window.performance.memory) {
        const { usedJSHeapSize, totalJSHeapSize } = window.performance.memory;
        console.log(`Memory Usage: ${Math.round((usedJSHeapSize / totalJSHeapSize) * 100)}%`);
      }
    };
  
    const interval = setInterval(logMemoryUsage, 5000); // Log memory usage every 5 seconds
  
    return () => clearInterval(interval); // Clean up when the component unmounts
  }, []);

  return (
    <div>
      <h1>BTC/USDT Real-Time Chart</h1>
      {currentPrice && <p>Current Price: ${currentPrice}</p>} {/* Display current price */}
      <Line data={chartData} />
    </div>
  );
}

export default App;