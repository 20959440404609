import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import PollHistoryChart from './PollHistoryChart';  // Adjust the import path as necessary

const LargeChart = ({ data, title }) => (
  <div className="bg-white rounded-lg shadow-md p-4 mt-4">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <XAxis dataKey="date" />
        <YAxis domain={['auto', 'auto']} />
        <Tooltip />
        <Line type="monotone" dataKey="D" stroke="#0000FF" strokeWidth={2} name="Democrat" />
        <Line type="monotone" dataKey="R" stroke="#FF0000" strokeWidth={2} name="Republican" />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

const largeCharts = {
  nationalPresidential: {
    title: "Who's Ahead in National Presidential Polls?",
    data: [
      { date: '2023-06', D: 48, R: 46 },
      { date: '2023-08', D: 47, R: 47 },
      { date: '2023-10', D: 49, R: 46 },
      { date: '2023-12', D: 48, R: 47 },
      { date: '2024-02', D: 50, R: 45 },
    ]
  },
  turkishPollHistory: {
    title: "Turkish Political Party Poll History",
    component: PollHistoryChart
  }
};

export { LargeChart, largeCharts };