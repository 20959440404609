import React, { useEffect, useState } from 'react';

const PollTable = () => {
  const [pollData2, setPollData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPollData = async () => {
      try {
        const response = await fetch('https://secimdb.nuritas.me/api/poll_history');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPollData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPollData();
  }, []);

  // Function to group data by month
  const groupByMonth = (data) => {
    return data.reduce((acc, curr) => {
      const month = `${curr.yil}-${curr.ay_int.toString().padStart(2, '0')}`;
      if (!acc[month]) acc[month] = [];
      acc[month].push(curr);
      return acc;
    }, {});
  };

  const groupedData = groupByMonth(pollData2);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>;
  }

  if (!Object.keys(groupedData).length) {
    return <div className="text-center">No polling data available.</div>;
  }

  return (

    <div className="overflow-x-auto"> {/* Add horizontal scrolling for small screens */}
      {Object.keys(groupedData).map((month) => (
        <div key={month}>
          <h2 className="text-xl font-bold mt-4">{month}</h2>
          <div className="w-full">
            <table className="w-full mt-2">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left">Tarihler</th>
                  <th className="px-4 py-2 text-left">Örneklem</th>
                  <th className="px-4 py-2 text-left">Şirket</th>
                  <th className="px-4 py-2 text-left">Sonuç</th>
                  <th className="px-4 py-2 text-left">Fark</th>
                </tr>
              </thead>
              <tbody>
                {groupedData[month].map((poll) => (
                  <tr key={poll.id} className="border-b">
                    <td className="px-4 py-2">{poll.tarih}</td>
                    <td className="px-4 py-2">{poll.orneklem}</td>
                    <td className="px-4 py-2">{poll.sirket}</td>
                    <td className="px-4 py-2">
                      <span className="text-orange-400 font-semibold">AKP {poll.akp}%</span>
                      <span className="mx-1">-</span>
                      <span className="text-red-400 font-semibold">{poll.chp} CHP%</span>
                    </td>
                    <td className="px-4 py-2">{poll.fark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <hr className="my-4" />
        </div>
      ))}
    </div>
  );
};

export default PollTable;