import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const PollHistoryChart = () => {
    const [pollData, setPollData] = useState([]);

    useEffect(() => {
        const fetchPollData = async () => {
            try {
                const response = await fetch('https://secimdb.nuritas.me/api/poll_history');
                const data = await response.json();

                // Filter out id 62 and sort by id in descending order
                const filteredData = data.filter(poll => poll.id !== 62);
                const sortedData = filteredData.sort((a, b) => b.id - a.id);

                // Process the data
                const processedData = sortedData.map(poll => ({
                    date: `${poll.yil}-${poll.ay_int.toString().padStart(2, '0')}`,
                    akp: parseFloat(poll.akp),
                    chp: parseFloat(poll.chp),
                    mhp: parseFloat(poll.mhp),
                    dem: parseFloat(poll.dem),
                }));

                setPollData(processedData);
            } catch (error) {
                console.error('Error fetching poll data:', error);
            }
        };

        fetchPollData();
    }, []);

    return (
        <div className="bg-white rounded-lg shadow-md p-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">Turkish Political Party Poll History</h3>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={pollData}>
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, 'auto']} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="akp" stroke="#FFA500" strokeWidth={2} name="AKP" dot={false} />
                    <Line type="monotone" dataKey="chp" stroke="#FF0000" strokeWidth={2} name="CHP" dot={false} />
                    <Line type="monotone" dataKey="mhp" stroke="#800080" strokeWidth={2} name="MHP" dot={false} />
                    <Line type="monotone" dataKey="dem" stroke="#008000" strokeWidth={2} name="DEM" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PollHistoryChart;
